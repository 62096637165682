<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <!-- <img :src="importedImg4" alt="" class="imgItem4" /> -->
        <ul class="msg2">
          <li>
            <span>Nombre</span>
            <a-input
              class="inputBox"
              v-model="formValues.email"
              placeholder="Por favor, introduzca"
            >
              <!-- <template #suffix>
                <img :src="mailIcon" />
              </template> -->
            </a-input>
          </li>
          <li>
            <span>Correo</span>
            <a-input
              class="inputBox"
              v-model="formValues.mailName"
              placeholder="Por favor, introduzca"
            >
              <!-- <template #suffix>
                <img :src="mailIcon" />
              </template> -->
            </a-input>
          </li>
          <li>
            <span>Tu pregunta</span>
            <a-textarea
              class="textareaBox"
              v-model="formValues.remark"
              placeholder="Por favor, complete su pregunta."
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
          </li>
          <li>
            <div>
              <a-button
                type="primary"
                :disabled="
                  formValues.mailName === '' || formValues.remark === ''
                "
                :class="{
                  submitButton: true,
                  btnBgcolor:
                    formValues.mailName !== '' && formValues.remark !== '',
                }"
                @click="submitFn"
                :loading="isLoading"
                >Enviar</a-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="clickBox1" @click="gotoPage('/')"></div>
        <div class="clickBox2" @click="gotoPage('/about')"></div>
        <div class="clickBox3" @click="gotoTop"></div>
        <div class="clickBox4" @click="gotoLink"></div>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
        <div
          class="clickBox6"
          @click="gotoPDF('Licencia de la Compañía')"
        ></div>
        <div
          class="clickBox7"
          @click="gotoPDF('Licencia de Servicios Financieros')"
        ></div>
        <div
          class="clickBox8"
          @click="gotoPDF('Documentos de Autorización de la Compañía')"
        ></div>
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <img :src="importedImg3" alt="" class="imgItem3" />
      </div>
    </div>
  
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6Box max-w-1400">
        <!-- <img :src="importedImg6" alt="" class="imgItem6" /> -->
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7Box max-w-1400">
        <!-- <img :src="importedImg7" alt="" class="imgItem7" /> -->
      </div>
    </div>
    <div class="imgBox8 min-w-1400">
      <div class="img8Box max-w-1400">
        <!-- <img :src="importedImg8" alt="" class="imgItem8" /> -->
      </div>
    </div>
    <div class="imgBox9 min-w-1400">
      <div class="img9Box max-w-1400">
        <!-- <img :src="importedImg9" alt="" class="imgItem9" /> -->
      </div>
    </div>
    <div class="imgBox10 min-w-1400">
      <div class="img10Box max-w-1400">
        <!-- <img :src="importedImg10" alt="" class="imgItem10" /> -->
      </div>
    </div>
    <div class="imgBox11 min-w-1400">
      <div class="img11Box max-w-1400">
        <!-- <img :src="importedImg11" alt="" class="imgItem11" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import { message } from "ant-design-vue";
import importedImg1 from "@/page/pcainfoyserinte/components/img/3_1.png";
import importedImg2 from "@/page/pcainfoyserinte/components/img/3_2.png";
import importedImg3 from "@/page/pcainfoyserinte/components/img/3_3.png";
// import importedImg4 from "@/page/pcainfoyserinte/components/img/3_4.png";
// import importedImg5 from "@/page/pcainfoyserinte/components/img/3_5.png";
// import importedImg6 from "@/page/pcainfoyserinte/components/img/3_6.png";
// import importedImg7 from "@/page/pcainfoyserinte/components/img/3_7.png";
// import importedImg8 from "@/page/pcainfoyserinte/components/img/3_8.png";
// import importedImg9 from "@/page/pcainfoyserinte/components/img/3_9.png";
// import importedImg10 from "@/page/pcainfoyserinte/components/img/3_10.png";
// import importedImg11 from "@/page/pcainfoyserinte/components/img/3_11.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      // importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      // importedImg8: importedImg8,
      // importedImg9: importedImg9,
      // importedImg10: importedImg10,
      // importedImg11: importedImg11,
      altText: "图片",
      formValues: {
        mailName: "",
        email: "",
        remark: "",
      },
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    gotoPage(path) {
      this.$router.push(path);
    },
    gotoPDF(fileName) {
      window.open(`/static/${fileName}.pdf`);
    },
    gotoLink() {
      window.open("https://cre.pcacreditoapp.com/m7xD0C5a2/To5K9n7Pa.html", "_blank");
    },
    submitFn() {
      if (this.formValues.mailName === "" || this.formValues.remark === "") {
        message.info("必填项不能为空");
        return;
      }
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        message.success("Hemos recibido sus comentarios y le responderemos dentro de las 48 horas");
        this.formValues = {
          mailName: "",
          email: "",
          remark: "",
        };
      }, 2000);
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background: "";
}
img {
  display: block;
}
.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box,
.img8Box,
.img9Box,
.img10Box,
.img11Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11 {
  width: 100%;
  margin: 0;
}
.img1Box {
  margin-top: 40px;
}
.img1Box,
.img3Box {
  padding: 0px 220px;
}
.img2Box {
  padding: 0px 450px;
}
.img2Box {
  margin: 120px 0;
}
.img3Box {
  margin-bottom: 130px;
}
.img1Box {
  position: relative;
}
.clickBox1 {
  position: absolute;
  background: transparent;
  width: 180px;
  height: 34px;
  top: 53%;
  left: 13.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox2 {
  position: absolute;
  background: transparent;
  width: 170px;
  height: 34px;
  top: 61.5%;
  left: 13.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox3 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 34px;
  top: 70%;
  left: 13.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox4 {
  position: absolute;
  background: transparent;
  width: 220px;
  height: 34px;
  top: 78.5%;
  left: 13.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.img2Box {
  position: relative;
}
.clickBox6 {
  position: absolute;
  background: transparent;
  width: 290px;
  height: 40px;
  top: 62%;
  left: 39.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox7 {
  position: absolute;
  background: transparent;
  width: 390px;
  height: 40px;
  top: 77%;
  left: 36.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
.clickBox8 {
  position: absolute;
  background: transparent;
  width: 520px;
  height: 32px;
  top: 92%;
  left: 32.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.img4Box{
  padding: 50px 200px;
}

.msg2 {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  /* width: 502px; */
  padding: 20px;
  gap: 10%;
  /* background-color: #F5F9F9; */
}
.msg2 li {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.msg2 li:nth-child(1) {
  width: 35%;
}
.msg2 li:nth-child(2) {
  width: 35%;
}
.msg2 li:nth-child(3) {
  width: 80%;
}
.msg2 li:nth-child(4) {
  width: 8%;
}

.msg2 li span {
  color: #333333;
  font-family: "Thonburi";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* opacity: 0.6; */
  padding-bottom: 10px;
}
.msg2 li input,
textarea {
  all: unset; /* 移除所有默认样式 */
  border: none; /* 去除边框 */
  outline: none; /* 去除聚焦时的轮廓 */
  background: none; /* 去除背景 */
  color: inherit; /* 继承父元素的文本颜色 */
  font: inherit; /* 继承父元素的字体 */
  /* border: 1px solid red; */
  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F2F5F9;
  padding: 10px;
}

.msg2 li a {
  width: 200px;
  height: 50px;
  line-height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #8a5d3c;
  color: #ffffff;
  text-align: center;
  font-family: "Thonburi";
  font-size: 20px;
  font-weight: 700;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputBox {
  /* font-size: 18px;
  border: 2px solid #cdcdcd;
  border-radius: 10px;
  height: 50px; */
}

.inputBox >>> .ant-input {
  font-size: 18px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  height: 56px;
  background-color: #ffffff;
}

/* .inputBox>>>.ant-input:focus {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
}

.inputBox>>>.ant-input:hover  {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
} */

.textareaBox {
  margin-top: 20px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  font-size: 18px;
}
.textareaBox .ant-input {
  height: 200px;
}

.submitButton {
  /* background-color: #00BFBF !important; */
  color: #ffffff;
  border: none;
  height: 60px;
  border-radius: 5px;
  margin-top: 100px;
  width: 160px;
  font-size: 18px;
}

.btnBgcolor {
  background-color: #2E64EF !important;
  color: #ffffff;
}

/* .submitButton:hover {
  background-color: #ffffff !important;
  color: #001C80;
} */

.inp {
  display: flex;
}
</style>
